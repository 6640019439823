import PageWrapper from "../components/pageWrapper";
function Err() {
    return  (
      <PageWrapper>


    <h2>
      404
    </h2>
    <p>
        Beam us back up, Mr. Scott.
    </p>
    <div><iframe src="https://giphy.com/embed/Wq7qKYRTNXVew"   frameBorder="0" class="giphy-embed" allowFullScreen></iframe></div>


    </PageWrapper>
    );
  
};

export default Err;