
import ProfilePicture from "../profilePicture";
function ChannelDetailSkeleton() {

    return (
        <>

        <ProfilePicture  size='xs' className='skeleton'/>
        <p className='skeleton'>Goofus rexus</p>

        </>




    )
};

export default ChannelDetailSkeleton;


            
