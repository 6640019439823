import PageWrapper from "../components/pageWrapper";
function Contributors() {
    return  (
    <PageWrapper>
<h2><i class="fa-solid fa-users"></i> Contributors</h2>
<h2>M. Altuğ Jakal</h2>
<p>Head Developer - Founder</p>

</PageWrapper>
);
  };

  export default Contributors;