import ProfilePicture from "../profilePicture";
function MessagesSkeleton() {

    return (
        <>
            <div className="chatbox">
                <div className="chat-container">



                    <div className="chat sent skeleton">
                        <p>Internet is failing huh?</p>
                    </div>

                    <div className="chat received skeleton">
                        <p>Yeah, look we are stuck in the loading screen</p>
                    </div>
                    <div className="chat received skeleton">
                        <p>Can it get worse than this?</p>
                    </div>

                    <div className="chat sent skeleton">
                        <p>Probably no</p>
                    </div>
                    <div className="chat sent skeleton">
                        <p>I hate these wannabe internet companies</p>
                    </div>

                    <div className="chat received skeleton">
                        <p>Rip you off for a solid buck</p>
                    </div>
                    
                    <div className="chat sent skeleton">
                        <p>Internet is failing huh?</p>
                    </div>

                    <div className="chat received skeleton">
                        <p>Yeah, look we are stuck in the loading screen</p>
                    </div>
                    <div className="chat received skeleton">
                        <p>Can it get worse than this?</p>
                    </div>





                    </div>
                </div >


        </>




            )
};

            export default MessagesSkeleton;