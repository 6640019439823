
function DefaultBar({children}) {
 
return (

<div className="defaultbar">
{children}
</div>
);
};

export default DefaultBar;